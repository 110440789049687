<template>
  <v-dialog
    v-model="dialog"
    scrollable
    transition="dialog-bottom-transition"
    max-width="550"
    persistent
  >
    <v-form>
      <v-card>
        <!-- form title -->
        <v-toolbar dark color="primary" class="elevation-0 px-3">
          <span class="headline">Agregar nuevo local</span>
        </v-toolbar>
        <!-- form content -->
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group name="nombre" :validator="$v.local.name">
                  <v-text-field
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Nombre del local"
                    placeholder="Nombre del local. Ej.: local 1"
                    required
                    filled
                    rounded
                    v-model="local.name"
                  />
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group
                  name="descripción"
                  :validator="$v.local.description"
                >
                  <v-text-field
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Descripción"
                    placeholder="Ingrese la descripción"
                    required
                    filled
                    rounded
                    v-model="local.description"
                  />
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group name="capacidad" :validator="$v.local.capacity">
                  <v-text-field
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Capacidad del local"
                    placeholder="Capacidad del local. Ej.: 45"
                    type="number"
                    min="0"
                    required
                    filled
                    rounded
                    v-model="local.capacity"
                  />
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group name="local" :validator="$v.local.area_id">
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    :loading="areas.isLoading"
                    :items="areas.data"
                    item-text="name"
                    item-value="id"
                    label="Areas"
                    required
                    filled
                    rounded
                    v-model="local.area_id"
                  ></v-select>
                </form-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end pt-0">
          <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
          <v-btn color="primary" depressed :loading="isLoading" @click="submit"
            >Agregar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {
  required,
  maxLength,
  numeric,
  minValue,
  integer,
} from "vuelidate/lib/validators";
import localsRepository from "@/repositories/localsRepository";
import { alphanumeric } from "@/core/plugins/vuelidate";

export default {
  name: "CreateLocalModal",
  props: {
    areas: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      local: {
        name: null,
        description: null,
        capacity: null,
        area_id: null,
      },
    };
  },
  methods: {
    create() {
      this.isLoading = true;
      localsRepository
        .createLocal(this.local)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data and hidding modal if operation has been completed
          this.$emit("add", { obj: data.data });
          this.closeModal();
        })
        .catch((error) => {
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            this.fireToast({
              icon: "error",
              title: "No ha sido posible crear el registro 🙁",
            });
            return;
          }
          if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            this.fireToast({
              icon: "error",
              title: "Ocurrió un error al comunicarse con el servidor 🙁",
            });
            return;
          }
          // Something happened in setting up the request and triggered an Error
          this.fireToast({
            icon: "error",
            title: "Ha ocurrido un error inesperado 🙁",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.create();
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.$v.$reset();
      this.resetLocal();
    },

    resetLocal() {
      this.local = {
        name: null,
        description: null,
        capacity: null,
        area_id: null,
      };
    },
  },
  validations: {
    local: {
      name: {
        required,
        maxLength: maxLength(255),
        alphanumeric,
      },
      description: {
        required,
        maxLength: maxLength(255),
        alphanumeric,
      },
      capacity: {
        required,
        numeric,
        integer,
        minValueValue: minValue(0),
      },
      area_id: {
        required,
        maxLength: maxLength(255),
      },
    },
  },
};
</script>
